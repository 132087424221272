import React from 'react';
import { graphql, Link } from 'gatsby';
import Image from 'gatsby-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {
  FaAngleDoubleDown,
  FaAngleDoubleRight,
  FaAngleDoubleLeft,
} from 'react-icons/fa';
import PropTypes from 'prop-types';
import BlockContent from '@sanity/block-content-to-react';
import * as Moment from 'moment';
import _ from 'lodash';
import NukaCarousel from 'nuka-carousel';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Carousel from '../components/Carousels/ProjectCarousel';
import { useMediaQuery, useTailwindConfig } from '../hooks';
import AwardsSection from '../components/AwardsSection';

const ProjectPageTemplate = ({ pageContext, data: { sanityProject } }) => {
  const sliderImages = _.sortBy(
    sanityProject.sliderImages,
    (o) => new Moment(o._createdAt)
  ).reverse();

  const tailwindConfig = useTailwindConfig();

  const isMedium = useMediaQuery(
    `(max-width: ${tailwindConfig.theme.screens.md})`
  );

  let slidesToShow = 3;
  if (isMedium) slidesToShow = 1;

  return (
    <Layout>
      <SEO title={pageContext.title} />
      <div className="relative">
        <div
          style={{ height: `50vh`, minHeight: `22rem` }}
          className="relative w-full mx-auto max-w-1920px"
        >
          <div className="absolute inset-0 flex">
            <Image
              className="flex-1"
              fluid={sanityProject.mainImage.asset.fluid}
              alt={pageContext.title}
              loading="eager"
            />
          </div>
        </div>

        <div className="absolute inset-0 flex items-end justify-center max-w-3xl p-4 mx-auto">
          <AnchorLink
            className="flex items-center justify-center px-2 py-1 leading-none bg-white"
            href={`#${pageContext.slug}`}
            offset="56"
          >
            <FaAngleDoubleDown className="text-green-500" />
          </AnchorLink>
        </div>
      </div>
      {/* <AwardsSection /> */}
      <section
        id={pageContext.slug}
        className="flex flex-wrap w-full mx-auto my-8 max-w-1920px md:mb-16"
      >
        <div className="w-full p-4 sm:px-12 lg:pl-24 md:w-1/2">
          <h1 className="w-full mb-4 text-4xl font-bold leading-none text-green-500 uppercase">
            {pageContext.title}
          </h1>
          <div className="pl-4 text-gray-600 border-l-4 border-green-500 lg:text-xl">
            <BlockContent
              blocks={sanityProject._rawDescription}
              className="copywriting"
            />

            <p className="mt-4">
              <Link
                className="flex items-center font-bold text-green-500 uppercase"
                to="/projects"
              >
                All Projects <FaAngleDoubleRight className="ml-1 text-xs" />
              </Link>
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center w-full p-4 sm:px-12 lg:pr-24 lg:text-xl md:w-1/2">
          <div className="w-full mt-4 border-4 border-green-500 md:max-w-sm">
            <div className="p-4">
              <p>
                <strong>Type:</strong>{' '}
                <span className="inline-block">
                  {sanityProject.projectType.title}
                </span>
              </p>
              <p>
                <strong>Value:</strong>{' '}
                <span className="inline-block">{sanityProject.value}</span>
              </p>
              <p>
                <strong>Location:</strong>{' '}
                <span className="inline-block">{sanityProject.location}</span>
              </p>
              {sanityProject.title !== 'Antilles' &&
              sanityProject.title !== 'Tobago' ? (
                <p>
                  <strong>Designed by:</strong>{' '}
                  <span className="inline-block">
                    {sanityProject.designer.name}
                  </span>
                </p>
              ) : null}
              <p>
                <strong>Completion Date:</strong>{' '}
                <span className="inline-block">
                  {sanityProject.ongoing
                    ? 'Ongoing'
                    : sanityProject.completionDate}
                </span>
              </p>
            </div>
            <div className="flex justify-end p-1 font-bold text-white bg-green-500">
              <div className="w-6">
                {pageContext.prev && (
                  <Link
                    className="flex items-center"
                    to={`/projects/${pageContext.prev.slug.current}`}
                  >
                    <FaAngleDoubleLeft />
                  </Link>
                )}
              </div>
              <div className="w-6">
                {pageContext.next && (
                  <Link
                    className="flex items-center"
                    to={`/projects/${pageContext.next.slug.current}`}
                  >
                    <FaAngleDoubleRight />
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>

        {sanityProject.awardTestimonials.map((award) => (
          <div
            className="relative w-full px-4 py-16 mt-8 text-white bg-slategreen sm:px-12 lg:px-24"
            key={award._key}
          >
            <span className="flex justify-end triangle" />
            <div className="relative flex flex-wrap items-center justify-center max-w-4xl mx-auto text-xl">
              {award.comment === '2022 Multiple Award Winner' ? null : (
                <svg
                  className="absolute top-0 left-0 w-24 -mt-12 fill-current sm:-ml-12"
                  style={{ color: `hsla(360, 100%, 100%, .33)` }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <g>
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z" />
                  </g>
                </svg>
              )}
              {award.comment === '2022 Multiple Award Winner' ? (
                <div className="w-full mt-12 text-5xl font-bold leading-none md:mt-0 md:w-1/2">
                  <p>{award.comment}</p>
                </div>
              ) : (
                <div className="w-full copywriting md:w-1/2">
                  <p>{award.comment}</p>
                </div>
              )}

              <div className="flex justify-center w-full mt-8 md:mt-0 md:w-1/2">
                <NukaCarousel
                  autoplay
                  autoplayInterval={5000}
                  cellAlign="center"
                  dragging
                  easing="easeCubicInOut"
                  enableKeyboardControls
                  slidesToShow={slidesToShow}
                  swiping
                  wrapAround
                  renderCenterLeftControls={null}
                  renderCenterRightControls={null}
                  renderBottomCenterControls={null}
                >
                  {award.images.map((image) => (
                    <Image
                      key={image.asset._id}
                      fluid={image.asset.fluid}
                      className="w-full"
                    />
                  ))}
                </NukaCarousel>
              </div>
            </div>
          </div>
        ))}
        <Carousel allFile={sliderImages} />
      </section>
    </Layout>
  );
};

ProjectPageTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};

export const pageQuery = graphql`
  query ProjectQuery($slug: String!) {
    sanityProject(slug: { current: { eq: $slug } }) {
      _id
      slug {
        current
      }
      title
      _rawDescription(resolveReferences: { maxDepth: 50 })
      projectType {
        title
      }
      value
      location
      designer {
        name
      }
      ongoing
      completionDate(formatString: "MMMM YYYY")
      mainImage {
        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
          }
        }
      }
      sliderImages {
        asset {
          _id
          _createdAt
          fluid(maxWidth: 1000) {
            ...GatsbySanityImageFluid
          }
        }
      }
      awardTestimonials {
        _key
        comment
        images {
          asset {
            _id
            fluid(maxWidth: 200) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;

export default ProjectPageTemplate;
