import React from 'react';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import Carousel from 'nuka-carousel';

import { useTailwindConfig, useMediaQuery } from '../../../hooks';
import './ProjectCarousel.css';

const ProjectCarousel = ({ allFile }) => {
  const tailwindConfig = useTailwindConfig();

  const isSmall = useMediaQuery(
    `(max-width: ${tailwindConfig.theme.screens.sm})`
  );

  const slidesToShow = isSmall ? 1 : 2;

  return (
    <div id="carousel" className="w-full px-4 py-16 sm:px-0">
      <Carousel
        autoplay
        autoplayInterval={5000}
        cellAlign="center"
        dragging
        easing="easeCubicInOut"
        enableKeyboardControls
        slidesToShow={slidesToShow}
        swiping
        transitionMode="scroll3d"
        wrapAround
        renderCenterLeftControls={({ previousSlide }) => (
          <button
            aria-label="Previous slide"
            className="px-2 py-1 leading-none bg-white"
            onClick={previousSlide}
            type="button"
          >
            <FaAngleDoubleLeft className="text-green-500" />
          </button>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <button
            aria-label="Next slide"
            className="px-2 py-1 leading-none bg-white"
            onClick={nextSlide}
            type="button"
          >
            <FaAngleDoubleRight className="text-green-500" />
          </button>
        )}
        renderBottomCenterControls={null}
        className="h-full overflow-visible outline-none focus:shadow-outline"
      >
        {allFile.map((node) => (
          <Image
            key={node.asset._id}
            fluid={node.asset.fluid}
            className="h-full"
          />
        ))}
      </Carousel>
    </div>
  );
};

ProjectCarousel.propTypes = {
  allFile: PropTypes.array,
};

export default ProjectCarousel;
